import React from 'react';
import Layout from './Layout';
import { graphql } from 'gatsby';
import SEO from './Seo';
import VK, { Comments } from 'react-vk';
import Helmet from 'react-helmet';
import { MDXRenderer } from 'gatsby-plugin-mdx';

export default ({ data, pageContext }) => {
    const { thisPost, prevPost, nextPost } = pageContext;
    const { frontmatter, body } = data.mdx;

    const renderPostNavigation = () => (
        <div className="ie-post-navigation">
            <div>
                {nextPost && (
                    <a href={nextPost.path} className="ie-link">
                        <div>←</div>
                        <div>{nextPost.title}</div>
                    </a>
                )}
            </div>
            <div>
                {prevPost && (
                    <a href={prevPost.path} className="ie-link">
                        <div style={{ textAlign: 'right' }}>{prevPost.title}</div>
                        <div>→</div>
                    </a>
                )}
            </div>
        </div>
    );

    return (
        <Layout withSidebar={false}>
            <SEO title={frontmatter.title} />
            {!frontmatter.public && (
                <Helmet
                    meta={[
                        {
                            name: 'robots',
                            content: 'noindex',
                        },
                    ]}
                />
            )}

            <h2 className="ie-post-title">{frontmatter.title}</h2>
            <div className="ie-post-date">{frontmatter.date}</div>
            {!frontmatter.public && (
                <div>
                    <div className="ie-post-private">Private</div>
                </div>
            )}
            <div className="ie-post-content">
                <MDXRenderer>{body}</MDXRenderer>
            </div>
            {process.env.NODE_ENV !== 'development' && (
                <VK apiId={7218045}>
                    <Comments
                        elementId="vk_comments"
                        pageId={thisPost.path}
                        options={{
                            limit: 20,
                            attach: 'photo,link',
                            autoPublish: 0,
                            pageUrl: thisPost.path,
                            pageTitle: frontmatter.title,
                        }}
                    />
                </VK>
            )}
            {frontmatter.public && renderPostNavigation()}
        </Layout>
    );
};

export const pageQuery = graphql`
    query PostQuery($path: String!) {
        mdx(frontmatter: { path: { eq: $path } }) {
            body
            frontmatter {
                date(formatString: "D MMMM YYYY года", locale: "ru")
                title
                public
            }
        }
    }
`;
